import styles from './start.module.css';

import { SalesWidget } from "@com.marathonium/web2-ui";

const Start = ({startDate}) => {
    return (
        <div className={('scrollhere ') + styles.start}>
            <div className="container">
                <div className={styles.start_block}>
                    <div className={styles.start_dates}>
                        <div className={styles.start_date}>Старт {window.lps_settings.dates.mainlands}</div>
                        <div className={styles.start_date}>Длительность 45 дней</div>
                        <div className={styles.start_title}>ЗАПИСАТЬСЯ НА КУРС<br/> <span>ПИТАНИЕ</span></div>
                        <div className={styles.start_form}>
                            <div className={styles.start_form_left}>
                                <div className={styles.start_form_title}>Курс &laquo;Питание&raquo;</div>
                                <div className={styles.start_form_subtitle}>Курс &laquo;Питание&raquo;, старт сразу после после оплаты</div>
                            </div>
                            <div className={styles.start_form_right}>
                                <div className={styles.start_form_discount}>
                                    <p>-10%</p>
                                </div>
                                <div className={styles.start_form_prices}>
                                    <span>5&nbsp;000&nbsp;руб.</span>
                                    <p>12&nbsp;990&nbsp;руб.</p>
                                </div>
                                <div className={styles.start_form_ot}>от&nbsp;2165 руб./мес.</div>
                            </div>
                        </div>
                        <div className="nutrition_start">
                        <SalesWidget id={window.lps_settings.forms.nutrition_id} />
                        </div>
                    </div>
                    <div className={styles.start_info}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/marafon_str2/tnkf_icon.svg" alt="" />
                        <p><span>ОТ 2&nbsp;165&nbsp;₽ В МЕСЯЦ</span></p>
                        <p>Этот курс можно забрать в рассрочку. На 6&nbsp;месяцев без процентов и&nbsp;предварительного взноса, <strong>от&nbsp;2&nbsp;165&nbsp;рублей в&nbsp;месяц</strong> (только для граждан РФ).<br/> Первый платёж через 30&nbsp;дней.</p>
                    </div>
                    <div className={styles.start_guarantee}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/coursepop3/guarantee.png" alt="" />
                        <div className={styles.start_guarantee__title}>Гарантия 100%</div>
                        <div className={styles.start_guarantee__text}>Мы уверены в&nbsp;своём продукте. Поэтому если в&nbsp;течение первых 3-х дней вы решите, что курс вам не&nbsp;подошёл по&nbsp;каким-либо критериям, мы вернём деньги.</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Start;