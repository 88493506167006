import { useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Navigation } from "swiper";
import Countdown from 'react-countdown';

import styles from './gifts.module.css';

const Gifts = ({scroll, renderer}) => {
    const navigationPrevRef = useRef(null)
    const navigationNextRef = useRef(null)

    return (
        <div className={styles.gifts}>
            <div className="container">

                <div className={styles.titles}>
                    <div>
                        <div className={styles.gifts_title}>3&nbsp;НОВЫХ ПОЛЕЗНЫХ ГАЙДА В&nbsp;ПОДАРОК</div>
                        <div className={styles.gifts_subtitle}>Которые помогут достигнуть результатов<br/> проще, быстрее и&nbsp;безопаснее</div>
                    </div>

                    <div className={styles.navigation}>
                        <div ref={navigationPrevRef} className={styles.slider_btn_prev}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2023/chp/land/arrow_slide.svg" alt="" />
                        </div>
                        <div ref={navigationNextRef} className={styles.slider_btn_next}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2023/chp/land/arrow_slide.svg" alt="" />
                        </div>
                    </div>
                </div>
            </div>
                <div className={styles.block}>
                        <Swiper
                            speed={800}
                            navigation={{
                                prevEl: navigationPrevRef.current,
                                nextEl: navigationNextRef.current,
                            }}
                            slidesPerView={'auto'}
                            spaceBetween={20}
                            loop
                            onSwiper={(swiper) => {
                                setTimeout(() => {
                                swiper.params.navigation.prevEl = navigationPrevRef.current
                                swiper.params.navigation.nextEl = navigationNextRef.current
                                
                                swiper.navigation.destroy()
                                swiper.navigation.init()
                                swiper.navigation.update()
                                })
                            }}
                            modules={[Navigation]}
                            className={`swiper_gt ${styles.swiper}`}
                        >
                            <SwiperSlide className={styles.slide}>
                                <div className={styles.slide_block}>
                                    <div className={styles.slide_img}>
                                        <img src="https://gymteam.kinescopecdn.net/img/l/2025/gymfeb/g1.png" alt="" />
                                    </div>
                                    <div className={styles.slide_info}>
                                        <span>гайд</span>
                                        <p>&laquo;Витамины как источник энергии для тренировок&raquo;</p>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide className={styles.slide}>
                                <div className={styles.slide_block}>
                                    <div className={styles.slide_img}>
                                        <img src="https://gymteam.kinescopecdn.net/img/l/2025/gymfeb/g2.png" alt="" />
                                    </div>
                                    <div className={styles.slide_info}>
                                        <span>гайд</span>
                                        <p>&laquo;Спортивное питание и&nbsp;добавки&raquo;</p>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide className={styles.slide}>
                                <div className={styles.slide_block}>
                                    <div className={styles.slide_img}>
                                        <img src="https://gymteam.kinescopecdn.net/img/l/2025/gymfeb/g3.png" alt="" />
                                    </div>
                                    <div className={styles.slide_info}>
                                        <span>гайд</span>
                                        <p>&laquo;Протоколы питания: плюсы, минусы и&nbsp;основные принципы&raquo;</p>
                                    </div>
                                </div>
                            </SwiperSlide>
                        </Swiper>
                </div>

                {/* <div className={styles.btn} onClick={scroll}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/method/arrow_circle_white.svg" alt="" />
                        <p>Забрать программу<br/> и&nbsp;бонусы</p>
                    </div>
                <div className={styles.promo_countdown}>
                    <p>Успей забрать курс по&nbsp;самой выгодной цене<br/> и&nbsp;получить ВСЕ бонусы</p>
                    <div className={styles.promo_countdown_timer}>
                        <Countdown date={Date.now() + 900000} renderer={renderer} />
                    </div>
                </div> */}
            </div>
                
    )
}

export default Gifts;