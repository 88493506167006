import Countdown from 'react-countdown';
import styles from './promo.module.css';

const Promo2 = ({scroll, date, renderer}) => {
    return (
        <div className={styles.promo}>
            <div className="container">
                <div className={styles.promoblock}>
                    <div className={styles.promo_pretitle}>Эксклюзивное предложение на&nbsp;48&nbsp;часов</div>
                    <div className={styles.promo_title}>Для участниц<br/> курса <img src="https://gymteam.kinescopecdn.net/img/l/2025/gymfeb/promo_title.png" alt="" /><br/> для зала</div>
                    <div className={styles.promo_subtitle}><b>Получите максимальный результат</b> от&nbsp;тренировок с&nbsp;помощью нового марафона питания для&nbsp;зала и&nbsp;умного бота для&nbsp;ежедневного контроля КБЖУ</div>                    
                    <div className={styles.promo_imgs}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2025/gymfeb/test3.png" alt="" />
                    </div>
                    <div className={styles.btns}>
                        <div className={styles.promo_btn} onClick={scroll}>
                            {/* <img src="https://gymteam.kinescopecdn.net/img/l/2024/method/arrow_circle_white.svg" alt="" /> */}
                            <p>Хочу результат!</p>
                        </div>
                        {/* <div className={styles.promo_asd}>Наибольшая выгода <span>81%</span></div> */}
                        {/* <div className={styles.promo_remains}>
                            <div className={styles.promo_remains_1}>Только до 23:59. <b>Далее спеццена сгорит</b></div>
                            <div className={styles.promo_remains_2}>осталось до&nbsp;окончания закрытого предложения</div>
                        </div> */}
                        {/* <div className={styles.promo_remains}>
                            <div className={styles.promo_remains_2}>{window.lps_settings.other.count}</div>
                            <div className={styles.promo_remains_1}>Осталось мест<br/> по&nbsp;суперцене:</div>
                        </div> */}
                        <div className={styles.promo_countdown}>
                            <div className={styles.promo_countdown_timer}>
                                <Countdown date={Date.now() + date} renderer={renderer} />
                            </div>
                            <p>осталось до&nbsp;окончания<br/> закрытого предложения</p>
                        </div>
                    </div>
                    {/* <div className={styles.mob1}>
                        <div className={styles.promo_remains}>
                            <div className={styles.promo_remains_2}>-15%</div>
                            <div className={styles.promo_remains_1}>Только до 23:59. <b>Далее спеццена сгорит</b></div>
                        </div>
                    </div> */}
                    <div className={styles.st1}>Успевайте воспользоваться предложением<br/> <b>и&nbsp;сэкономить 81%!</b></div>
                    {/* <div className={styles.st2}>Далее цена повысится</div> */}
                    {/* <div className={styles.promo_countdown}>
                        <p>Спецпредложение сгорит через:</p>
                        <div className={styles.promo_countdown_timer}>
                            <Countdown date={Date.now() + date} renderer={renderer} />
                        </div>
                    </div> */}
                    {/* <div className={styles.promo_remains}>
                        <div className={styles.promo_remains_1}>Осталось мест<br/> по&nbsp;суперцене:</div>
                        <div className={styles.promo_remains_2}>{window.lps_settings.other.count}</div>
                    </div> */}
                    
                </div>
        </div>
        </div>
    )
}

export default Promo2;