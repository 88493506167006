import Countdown from "react-countdown";
import { SalesWidget } from "@com.marathonium/web2-ui";
import styles from "./start.module.css";
import { useInstance } from "@com.marathonium/web2-utils";
import { useState } from "react";

const Start = ({ renderer, date }) => {
	const [widget] = useInstance();
    const [active, setActive] = useState(1);
	
	return (
		<div className={styles.oh}>

			<div className={"scrollhere " + styles.start}>
				<div className="container">
					<div className={styles.block}>
						<div className={styles.title}>ЕДИНСТВЕННЫЙ ШАНС ПОЛУЧИТЬ ЭКСКЛЮЗИВНУЮ СИСТЕМУ ПИТАНИЯ <br/>ДЛЯ ЗАЛА С&nbsp;ВЫГОДОЙ ДО&nbsp;81%</div>
						<div className={styles.subtitle}>Успейте забрать новый марафон по&nbsp;питанию для зала и&nbsp;умного ИИ-бота, <br/>который заменит вам нутрициолога</div>
						<div className={styles.remains_n}>
							<div className={styles.timer}>
								<p>Закрытое <br/>спецпредложение <br/>сгорит через:</p>
								<Countdown date={Date.now() + date} renderer={renderer} />
							</div>
							{/* <div className={styles.remains_n_place}>Осталось мест по&nbsp;суперцене: {window.lps_settings.other.count}</div> */}
						</div>
                        <div className={styles.inf}>
                            <div className={styles.inf_item}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2025/gymfeb/calendar.jpg" alt="" />
								<div>
									<p>Старт тренировок и&nbsp;марафона &mdash;</p>
									<p>17&nbsp;февраля</p>
								</div>
                            </div>
                            <div className={styles.inf_item}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2025/gymfeb/clock.jpg" alt="" />
								<div>
									<p>Доступ ко&nbsp;всем материалам и&nbsp;умному боту &mdash;</p>
									<p>4,5&nbsp;месяца</p>
								</div>
                            </div>
                        </div>

                        <div className={styles.choose}>
                            <div className={`${styles.choose_item} ${active == 1 &&styles.choose_item_active}`} onClick={()=>{widget.resetOrder();widget.reset();widget.selectByIndex(0);setActive(1)}}>
                                <div className={styles.choose_title}>
									<b>Спецпредложение для участниц курса для зала</b>
									<p>+ Новый 7-дневный марафон по&nbsp;питанию</p>
									{/* <ul>
										<li>&bull; Уроки для тех, кто тренируется в&nbsp;зале</li>
										<li>&bull; Готовое меню на&nbsp;7&nbsp;дней + подкасты и&nbsp;доп. материалы</li>
										<li>&bull; Разбор тарелок&nbsp;&mdash; учимся составлять сбалансированный рацион</li>
										<li>&bull; Поддержка нутрициолога в&nbsp;чате каждый день</li>
									</ul> */}
									<p>+ ИИ-бот по&nbsp;питанию:</p>
									{/* <ul>
										<li>&bull; Индивидуальный расчёт КБЖУ на&nbsp;основе ваших данных</li>
										<li>&bull; Анализ рациона по&nbsp;фото, тексту или голосу</li>
										<li>&bull; Советы по&nbsp;питанию на&nbsp;день и&nbsp;корректировки рациона</li>
										<li>&bull; Меню на&nbsp;3&nbsp;дня под вашу цель (похудение, набор, баланс)</li>
									</ul> */}
									<p>+ Все тренировки курса для зала</p>
									<p>+ Программа сбалансированного питания</p>
								</div>
								
								<div className={styles.fgf}>
									<div>
										<div className={styles.credit}>от 665 ₽/мес</div>
										<div className={styles.choose_prices}>
											<p>3&nbsp;990&nbsp;<b>₽</b></p>
											<p>20&nbsp;990&nbsp;<b>₽</b></p>
										</div>
									</div>
									<span>-81%</span>
								</div>
                            </div>
                        </div>
						<div className={styles.info}>
							<div className={styles.info_item}>
								<p>+ Чат с&nbsp;поддержкой кураторов на&nbsp;1&nbsp;месяц</p>
							</div>
							<div className={styles.info_item}>
								<p>+ 3&nbsp;полезных гайда для улучшения результата в&nbsp;подарок</p>
							</div>
						</div>

						<div className={styles.rowstart}>
						<div className={styles.pay}>
							<div className={styles.pay_item}>
								<img
									src="https://gymteam.kinescopecdn.net/img/l/2024/presale_blackfriday/bank1.png"
									alt=""
								/>
								<div>
								<p>Рассрочка без процентов<br/> от&nbsp;665&nbsp;₽ в&nbsp;месяц</p>
								<span>
									Вы&nbsp;можете оформить заказ в&nbsp;рассрочку:
									на&nbsp;6&nbsp;месяцев без процентов
									<br /> и&nbsp;предварительного взноса. Только для
									граждан&nbsp;РФ.
								</span>
								</div>
							</div>
							<div className={styles.pay_item}>
								<img
									src="https://gymteam.kinescopecdn.net/img/l/2024/presale_blackfriday/bank2.png"
									alt=""
								/>
								<div>
								<p>Оплати Долями, без банка,<br/> переплат и&nbsp;рисков</p>
								<span>
									Не&nbsp;надо ждать до&nbsp;зарплаты&nbsp;&mdash; разделите
									платёж на&nbsp;4&nbsp;части от&nbsp;<b>997&nbsp;₽</b>.
									<br /> Всего на&nbsp;6&nbsp;недель. Только для граждан РФ
								</span>
								</div>
							</div>
						</div>

						<div className="gymfeb_start">
								<SalesWidget
									hook={widget}
									id={window.lps_settings.forms.coursegym_february_spec_id}
									buttonText="ОФОРМИТЬ ЗАКАЗ"
								/>
						</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Start;
