import styles from './block2.module.css';

const Block2 = ({scroll}) => {
    return (
        <div className={styles.prize}>
            <div className="container">
                <div className={styles.b4}>
                    <div className={styles.b4_left}>
                        <div>
                            <p>Новая СИСТЕМА питания<br/> для тренировок в&nbsp;зале</p>
                            <div className={styles.btn} onClick={scroll}>Хочу новую систему питания</div>
                        </div>
                    </div>
                    <div className={styles.b4_right}>
                        <div className={styles.b4_right_item}>
                            <div className={styles.b4_right_item_img}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2025/gymfeb/us1.jpg" alt="" />
                                <span>Эксклюзив этого потока</span>
                            </div>
                            <div className={styles.b4_right_item_title}>Новый 7-дневный марафон питания<br/> для зала от&nbsp;личного нутрициолога Кати</div>
                            <div className={styles.b4_right_item_text}>Марафон в&nbsp;живом формате прoведёт личный нутрициолог Вероника Гусакова. Разберётесь, как правильно питаться до&nbsp;и&nbsp;после тренировок, какие спортивные добавки действительно работают и&nbsp;как составлять тарелки для максимального результата. Получите готовое меню на&nbsp;неделю, ежедневные аудиоподкасты и&nbsp;поддержку нутрициолога. В&nbsp;итоге&nbsp;&mdash; улучшите форму, ускорите прогресс и&nbsp;начнёте питаться осознанно.</div>
                        </div>
                        <div className={styles.b4_right_item}>
                            <div className={styles.b4_right_item_img}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2025/gymfeb/us2.jpg" alt="" />
                                <span>Эксклюзив этого потока</span>
                            </div>
                            <div className={styles.b4_right_item_title}>Умный бот для индивидуального контроля питания</div>
                            <div className={styles.b4_right_item_text}>Персональный помощник, который легко рассчитает вашу норму КБЖУ исходя из&nbsp;вашего профиля и&nbsp;целей, даст индивидуальны ежедневные рекомендации и&nbsp;поможет достигнуть цели&nbsp;&mdash; похудеть, набрать массу или удержать форму. Просто загрузите свои данные, а&nbsp;дальше бот будет анализировать ваш рацион, подскажет что изменить в&nbsp;рационе, и&nbsp;даже составит меню на&nbsp;несколько дней по&nbsp;вашему запросу. Отправляйте запросы текстом, голосом или фото&nbsp;&mdash; бот всё разберёт и&nbsp;скорректирует питание. Доступен 24/7.</div>
                        </div>
                        <div className={styles.b4_right_item}>
                            <div className={styles.b4_right_item_img}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2025/gymfeb/us3.jpg" alt="" />
                            </div>
                            <div className={styles.b4_right_item_title}>Питайтесь по&nbsp;простому правилу<br/> &laquo;здоровой тарелки&raquo; 4&nbsp;раза в&nbsp;день<br/> без жёстких ограничений</div>
                            <div className={styles.b4_right_item_text}>Наша система питания основывается на&nbsp;правиле &laquo;здоровой тарелки&raquo;&nbsp;&mdash; это простой принцип питания, который позволяет питаться 4&nbsp;раза в&nbsp;день без диет, жёстких ограничений и&nbsp;дорогих продуктов. Такая программа помогает легко сбросить лишние килограммы и&nbsp;поддерживать крутое качество тела.</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Block2;
