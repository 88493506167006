import styles from './info.module.css';

const Info = ({scroll}) => {
    return (
        <div className={styles.info}>
            <div className="container">
                <div className={styles.title}>ЗАБЕРИТЕ<br/> КУРС ДЛЯ ЗАЛА<br/> ПРЯМО СЕЙЧАС</div>
                <div className={styles.subtitle}>Курс для всех, кто уже тренируется или только<br/> планирует пойти в тренажёрку. Если вы не знаете,<br/> с чего начать или недовольны своими результатами</div>
                <div className={styles.newcourse_t1}><img src="https://gymteam.kinescopecdn.net/img/l/2023/rasprozarka2/ic1.svg" alt="" /> Старт сразу после оплаты</div>
                <div className={styles.newcourse_t2}><img src="https://gymteam.kinescopecdn.net/img/l/2023/rasprozarka2/ic3.svg" alt="" /> График — 3 раза в неделю</div>
                <div className={styles.block}>
                    <div className={styles.item}>
                        <div className={styles.first}>
                            <div className={styles.name}>КУРС ДЛЯ ЗАЛА ПОЛНОСТЬЮ</div>
                            <div className={styles.boxes}>
                                <div>Доступ: 5 месяцев</div>
                                <div>Длительность: 4,5 месяца</div>
                            </div>
                            <ul className={styles.ul}>
                                <li>Все 4 блока тренировок</li>
                                <li>Меню и конструктор рациона</li>
                                <li>Путеводитель «Оборудование и инвентарь в тренажёрном зале»</li>
                                <li>Сборник «Продукты с высоким содержанием белка для набора мышечной массы»</li>
                                <li>Гайд «Правила хорошего тона в тренажёрном зале»</li>
                                <li>Гайд «Как правильно читать показатели умных весов»</li>
                                <li>Инструкция «Одежда, обувь и аксессуары для фитнеса: как выбрать и ухаживать»</li>
                                <li>Инструкция «Дисциплина: как начать ходить в тренажёрный зал с удовольствием»</li>
                            </ul>
                            <div className={styles.res}><b>Результат:</b> Освоите правильную технику выполнения, наработаете силу и выносливость, увеличите мышечные объёмы. В итоге создадите спортивное, рельефное тело.</div>
                            <div className={styles.price}>9&nbsp;990&nbsp;₽</div>
                        </div>
                    </div>
                    {/* <div className={styles.item}>
                        <div className={styles.second}>
                            <div className={styles.name}>ТОЛЬКО первый БЛОК «ТЕХНИКА ВЫПОЛНЕНИЯ»</div>
                            <div className={styles.boxes}>
                                <div>Доступ: 2 месяца</div>
                                <div>Длительность: 1,5 месяца</div>
                            </div>
                            <ul className={styles.ul}>
                                <li>1-й блок – 6 тренировок</li>
                                <li>Меню и конструктор рациона</li>
                                <li>Путеводитель «Оборудование и инвентарь в тренажёрном зале»</li>
                                <li>Сборник «Продукты с высоким содержанием белка для набора мышечной массы»</li>
                                <li>Гайд «Правила хорошего тона в тренажёрном зале»</li>
                                <li>Гайд «Как правильно читать показатели умных весов»</li>
                            </ul>
                            <div className={styles.res}><b>Результат:</b> На низких весах освоите правильную технику выполнения, чтобы на этой базе продолжать тренинг — увеличивать силы, выносливость и наращивать объёмы</div>
                            <div className={styles.more}>
                                <p><b>После этого блока вы можете отдельно приобрести блоки:</b></p>
                                <ul>
                                    <li>– Увеличение силовых показателей</li>
                                    <li>– Наработка выносливости</li>
                                    <li>– Работа над мышечными объёмами</li>
                                </ul>
                            </div>
                            <div className={styles.price}>3&nbsp;141&nbsp;₽ <span>3&nbsp;490&nbsp;₽</span></div>
                        </div>
                    </div> */}
                </div>
                <div onClick={scroll} className={styles.btn}><p>Перейти к оформлению</p></div>
            </div>
        </div>
    )
}

export default Info;