import styles from './what.module.css';

const What = () => {
    return (
        <div className={styles.what}>
            <div className="container">
                <div className={styles.title}>САМЫЙ КОРОТКИЙ И&nbsp;ПОНЯТНЫЙ ПУТЬ К&nbsp;ФИГУРЕ МЕЧТЫ</div>
                <div className={styles.block}>
                    <div className={styles.item}>
                        <div className={styles.item_img}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2025/gymfeb/what_1.jpg" alt="" />
                        </div>
                        <div className={styles.item_tag}><b>Эксклюзив</b> этого потока</div>
                        <div className={styles.item_title}>Новый 7-дневный живой марафон по&nbsp;питанию</div>
                        <div className={styles.item_subtitle}>с&nbsp;обратной связью от&nbsp;личного нутрициолога Кати Вероники Гусаковой</div>
                    </div>
                    <div className={styles.item}>
                        <div className={styles.item_img}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2025/gymfeb/what_2.jpg" alt="" />
                        </div>
                        <div className={styles.item_tag}><b>Эксклюзив</b> этого потока</div>
                        <div className={styles.item_title}>Умный ИИ-бот</div>
                        <div className={styles.item_subtitle}>с&nbsp;ежедневными рекомендациями в&nbsp;питании и&nbsp;подсчётом КБЖУ по&nbsp;фото, тексту или аудио</div>
                    </div>
                    <div className={styles.item}>
                        <div className={styles.item_img}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2025/gymfeb/what3.jpg" alt="" />
                        </div>
                        <div className={styles.item_tag}>Ко&nbsp;всем материалам набора</div>
                        <div className={styles.item_title}>4,5&nbsp;месяца доступа</div>
                        <div className={styles.item_subtitle}></div>
                    </div>
                    <div className={styles.item}>
                        <div className={styles.item_img}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2025/gymfeb/what4.jpg" alt="" />
                        </div>
                        <div className={styles.item_tag}>С&nbsp;доступом 1&nbsp;месяц</div>
                        <div className={styles.item_title}>Чат с&nbsp;куратором</div>
                        <div className={styles.item_subtitle}></div>
                        
                    </div>
                    <div className={styles.item}>
                        <div className={styles.item_img}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2025/gymfeb/what5.jpg" alt="" />
                        </div>
                        <div className={styles.item_tag}>Питайтесь 4&nbsp;раза в&nbsp;день и&nbsp;худейте</div>
                        <div className={styles.item_title}>Программа питания для зала</div>
                        <div className={styles.item_subtitle}></div>
                    </div>
                    <div className={styles.item}>
                        <div className={styles.item_img}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2025/gymfeb/gueds2.jpg" alt="" />
                        </div>
                        <div className={styles.item_tag}>Для улучшения результатов</div>
                        <div className={styles.item_title}>3 полезных гайда</div>
                        <div className={styles.item_subtitle}></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default What;