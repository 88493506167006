import Countdown from 'react-countdown';
import { SalesWidget } from "@com.marathonium/web2-ui";
import styles from './start.module.css';

const Start = ({date, renderer}) => {
    return (
        <div className={('scrollhere ') + styles.start}>
            <div className="container">
                <div className={styles.start_block}>
                    <div className={styles.start_title}>ЗАПИСАТЬСЯ НА <span>НОВЫЙ КУРС</span><br/> для зала</div>
                    {/* <div className={styles.start_subtitle}><b>чтобы участвовать в&nbsp;дополнительном розыгрыше {window.lps_settings.texts.text1_4}&nbsp;000&nbsp;₽,</b> {window.lps_settings.texts.text3_1}</div> */}
                    <div className={styles.start_countdown}>
                        <p>Ваша выгода сгорит через:</p>
                        <div className={styles.start_countdown_timer}>
                            <Countdown date={Date.now() + date} renderer={renderer} />
                        </div>
                    </div>
                    <div className={styles.start_form}>
                            <div className={styles.start_form_left}>
                                <div className={styles.start_form_title}>Курс для зала</div>
                                <div className={styles.start_form_subtitle}>Курс для зала, старт сразу после после оплаты</div>
                            </div>
                            <div className={styles.start_form_right}>
                                <div className={styles.start_form_discount}>
                                    <p>-10%</p>
                                </div>
                                <div className={styles.start_form_prices}>
                                    <span>5&nbsp;000&nbsp;руб.</span>
                                    <p>9&nbsp;990&nbsp;руб.</p>
                                </div>
                                <div className={styles.start_form_ot}>от&nbsp;1665 руб./мес.</div>
                            </div>
                        </div>
                    <div className="rasprozarka10_start">
                        <SalesWidget id={window.lps_settings.forms.coursegym_id} />
                    </div>
                    <div className={styles.start_credit}>
                        <p>Платите с&nbsp;любой карты&nbsp;— выпущенной российским или&nbsp;иностранным банком.</p>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2023/inshape/tnkf.svg" alt="" />
                        <div className={styles.start_credit__title}>Рассрочка без&nbsp;процентов от&nbsp;1&nbsp;665&nbsp;₽ в&nbsp;месяц</div>
                        <div className={styles.start_credit__text}>
                            <p>На&nbsp;6&nbsp;месяцев без&nbsp;процентов и&nbsp;предварительного взноса (только&nbsp;для&nbsp;граждан&nbsp;РФ).</p>
                        </div>
                    </div>
                    <div className={styles.start_parts}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/pregnancy/start_icon.png" alt="" />
                        <div className={styles.start_parts__title}>Оплатите Долями<br/> без&nbsp;банка, переплат и&nbsp;рисков</div>
                        <div className={styles.start_parts__text}>Не надо ждать до&nbsp;зарплаты — разделите платёж <strong>на&nbsp;4&nbsp;части<br/> от&nbsp;2&nbsp;498&nbsp;₽</strong>. Всего на&nbsp;6&nbsp;недель.</div>
                        <div className={styles.start_parts_block}>
                            <div className={styles.start_parts_item}>
                                <div className={styles.start_parts_number}><p>1</p></div>
                                <p>от 2&nbsp;498 ₽<br/> <span>сегодня</span></p>
                            </div>
                            <div className={styles.start_parts_item}>
                                <div className={[styles.start_parts_number, styles.start_parts_number2].join(' ')}><p>2</p></div>
                                <p>от 2&nbsp;498 ₽<br/> <span>через 2&nbsp;недели</span></p>
                            </div>
                            <div className={styles.start_parts_item}>
                                <div className={[styles.start_parts_number, styles.start_parts_number3].join(' ')}><p>3</p></div>
                                <p>от 2&nbsp;498 ₽<br/> <span>через 4&nbsp;недели</span></p>
                            </div>
                            <div className={styles.start_parts_item}>
                                <div className={[styles.start_parts_number, styles.start_parts_number4].join(' ')}><p>4</p></div>
                                <p>от 2&nbsp;498 ₽<br/> <span>через 6&nbsp;недель</span></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Start;