// import Countdown from 'react-countdown';
import styles from './block1.module.css';

const Block1 = () => {
    return (
        <div className={styles.block1}>
            <div className="container">
                <div className={styles.block}>
                    <div className={styles.block_left}>
                        <div className={styles.block_img}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2025/gymfeb/prize_1.jpg" alt="" />
                        </div>
                        <div className={styles.block_title}>Получайте ответы<br/> на&nbsp;вопросы и&nbsp;мотивацию<br/> от&nbsp;кураторов целый месяц</div>
                        <div className={styles.block_text}>При покупке вы&nbsp;получите доступ в&nbsp;телеграм-чат с&nbsp;куратором и&nbsp;другими участницами потока на&nbsp;1&nbsp;месяц</div>
                    </div>
                    <div className={styles.block_right}>
                        <div className={styles.block_phone}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/salecoursegym/phone2.png" alt="" />
                            <div className={styles.block_phone_1}>
                                <div>(1)</div>
                                <div>Ответы на&nbsp;любые<br/> вопросы по&nbsp;тренировкам</div>
                            </div>
                            <div className={styles.block_phone_2}>
                                <div>(2)</div>
                                <div>Поддержка<br/> и&nbsp;мотивация</div>
                            </div>
                            <div className={styles.block_phone_3}>
                                <div>(3)</div>
                                <div>Общение<br/> с&nbsp;единомышленницами</div>
                            </div>
                        </div>
                    </div>
                </div>                
            </div>
        </div>
        
    )
}

export default Block1;
