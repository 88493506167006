import React, { useEffect } from 'react';

import './style.css';
import { DocumentDescription, DocumentTitle } from '../../components/DocumentMeta';
import Promo from './components/Promo';
import Start from './components/Start';
import Block1 from './components/Block1';
import What from './components/What';
import Block2 from './components/Block2';
import Gifts from './components/Gifts';
import Faq from './components/Faq';
const Footer = React.lazy(() => import('../../components/Footer'));

export const L2025_02_Coursegym_spec = () => {
    useEffect(() => {
      document.body.id = 'reset'
      document.body.parentElement.id = 'html_reset'
    }, [])

    const scroll = () => {
      const section = document.querySelector( '.scrollhere' );
      section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
    };
    // let nextMidnight = new Date();
    // let nextMidnight = new Date(2024,6,19,0,0,0);
    let nextMidnight = new Date(Date.UTC(2025,1,10,6,0,0));
    // nextMidnight.setHours(24, 0, 0, 0);
    const now = new Date();
    const date = nextMidnight.getTime() - now.getTime();
    const renderer = ({days, hours, minutes, seconds, completed}) => {
        let hourzero = '';
        let minuteszero = '';
        let secondszero = '';
        if(days>0) {hours += 24*days}
        if(hours<10) {hourzero = '0'}
        if(minutes<10) {minuteszero = '0'}
        if(seconds<10) {secondszero = '0'}
        return (
            <div>
                <div>{hourzero}{hours} <span>:</span></div>
                <div>{minuteszero}{minutes} <span>:</span></div>
                <div>{secondszero}{seconds}</div>
            </div>
        );
    };
    
    return (
      <>
        <DocumentTitle value='Спецпредложение для участниц прошлых потоков на Курс для зала с эксклюзивными новинками с выгодой 81%'/>
        <DocumentDescription value='Выгода 81% на эксклюзивный поток Курса для зала только 48 часов' />
        <div className='feb_gym'>
            <Promo renderer={renderer} date={date} scroll={scroll}/>
            <What />
            <Block2 scroll={scroll} />
            <Block1 />
            <Gifts />
            <Start renderer={renderer} date={date} />
            <Faq scroll={scroll} />
            <Footer isColor='#fff' />
        </div>
      </>
    );
};