import Countdown from 'react-countdown';
import styles from './start.module.css';
import { SalesWidget } from "@com.marathonium/web2-ui";

const Start = ({date, renderer}) => {
    return (
        <div className={('scrollhere ') + styles.start}>
            <div className="container">
                <div className={styles.start_block}>
                    <div className={styles.start_dates}>
                        <div className={styles.start_date}>Старт {window.lps_settings.dates.mainlands}</div>
                        <div className={styles.start_date}>Длительность 21 день</div>
                        <div className={styles.start_title}>ЗАПИСАТЬСЯ НА МАРАФОН<br/> КРАСИВОЙ <span>ПОПЫ 1.0</span></div>
                        <div className={styles.start_form}>
                            <div className={styles.start_form_left}>
                                <div className={styles.start_form_title}>Марафон &laquo;Красивой и&nbsp;упругой попы&nbsp;1.0&raquo;</div>
                                <div className={styles.start_form_subtitle}>Марафон &laquo;Красивой и&nbsp;упругой попы&nbsp;1.0&raquo;, старт сразу после после оплаты</div>
                            </div>
                            <div className={styles.start_form_right}>
                                <div className={styles.start_form_discount}>
                                    <p>-10%</p>
                                </div>
                                <div className={styles.start_form_prices}>
                                    <span>5&nbsp;000&nbsp;руб.</span>
                                    <p>6&nbsp;490&nbsp;руб.</p>
                                </div>
                                <div className={styles.start_form_ot}>от&nbsp;1082 руб./мес.</div>
                            </div>
                        </div>
                        <div class="marafonpopa1_start">
                            <SalesWidget id={window.lps_settings.forms.marafonpopa1_id} />
                        </div>
                        <div className={styles.countdown_timer}>
                            <p>Успей забрать марафон по&nbsp;самой выгодной цене</p>
                            <Countdown date={Date.now() + date} renderer={renderer} />
                        </div>
                    </div>
                    <div className={styles.start_info}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/marafon_str2/tnkf_icon.svg" alt="" />
                        <p><span>ОТ 1082 ₽ В МЕСЯЦ</span></p>
                        <p>Этот марафон можно забрать в рассрочку. На 6&nbsp;месяцев без процентов и&nbsp;предварительного взноса, <strong>от 1082&nbsp;рублей в&nbsp;месяц</strong> (только для граждан РФ).<br/> Первый платёж через 30&nbsp;дней.</p>
                    </div>
                    <div className={styles.start_guarantee}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/marafon_fatburning/guarantee_icon.png" alt="" />
                        <div className={styles.start_guarantee__title}>Гарантия 100%</div>
                        <div className={styles.start_guarantee__text}>Мы уверены в&nbsp;своём продукте. Поэтому если в&nbsp;течение первых 3-х дней вы решите, что марафон вам не&nbsp;подошёл по&nbsp;каким-либо критериям, мы вернём деньги.</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Start;