import Countdown from 'react-countdown';
import styles from './start.module.css';
import { SalesWidget } from "@com.marathonium/web2-ui";

const Start = ({date, renderer}) => {
    return (
        <div className={('scrollhere ') + styles.start}>
            <div className="container">
                <div className={styles.start_block}>
                    <div className={styles.start_dates}>
                        <div className={styles.start_date}>Старт {window.lps_settings.dates.mainlands}</div>
                        <div className={styles.start_date}>Длительность 30 дней</div>
                        <div className={styles.start_title}>ЗАПИСАТЬСЯ<br/> НА <span>АНТИЦЕЛЛЮЛИТНЫЙ</span> МАРАФОН</div>
                        
                        <div className={styles.start_form}>
                            <div className={styles.start_form_left}>
                                <div className={styles.start_form_title}>Марафон &laquo;Антицеллюлитный&raquo;</div>
                                <div className={styles.start_form_subtitle}>Марафон &laquo;Антицеллюлитный&raquo;, старт сразу после после оплаты</div>
                            </div>
                            <div className={styles.start_form_right}>
                                <div className={styles.start_form_discount}>
                                    <p>-10%</p>
                                </div>
                                <div className={styles.start_form_prices}>
                                    <span>7&nbsp;500&nbsp;руб.</span>
                                    <p>9&nbsp;990&nbsp;руб.</p>
                                </div>
                                <div className={styles.start_form_ot}>от&nbsp;1665 руб./мес.</div>
                            </div>
                        </div>
                        <div class="anticellulite_start">
                            <SalesWidget id={window.lps_settings.forms.anticellulite_id} />
                        </div>
                        <div className={styles.countdown_timer}>
                            <p>Успей забрать марафон по&nbsp;самой выгодной цене</p>
                            <Countdown date={Date.now() + date} renderer={renderer} />
                        </div>
                    </div>
                    {/* <div className={styles.start_parts}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/pregnancy/start_icon.png" alt="" />
                        <div className={styles.start_parts__title}>Оплатите Долями без&nbsp;банка,<br/> переплат и&nbsp;рисков</div>
                        <div className={styles.start_parts__text}>Не надо ждать до&nbsp;зарплаты — разделите платёж <strong>на&nbsp;4&nbsp;части<br/> по&nbsp;1&nbsp;250&nbsp;₽</strong>. Всего на&nbsp;6&nbsp;недель.</div>
                        <div className={styles.start_parts_block}>
                            <div className={styles.start_parts_item}>
                                <div className={styles.start_parts_number}><p>1</p></div>
                                <p>1250 ₽<br/> <span>сегодня</span></p>
                            </div>
                            <div className={styles.start_parts_item}>
                                <div className={[styles.start_parts_number, styles.start_parts_number2].join(' ')}><p>2</p></div>
                                <p>1250 ₽<br/> <span>через 2&nbsp;недели</span></p>
                            </div>
                            <div className={styles.start_parts_item}>
                                <div className={[styles.start_parts_number, styles.start_parts_number3].join(' ')}><p>3</p></div>
                                <p>1250 ₽<br/> <span>через 4&nbsp;недели</span></p>
                            </div>
                            <div className={styles.start_parts_item}>
                                <div className={[styles.start_parts_number, styles.start_parts_number4].join(' ')}><p>4</p></div>
                                <p>1250 ₽<br/> <span>через 6&nbsp;недель</span></p>
                            </div>
                        </div>
                        <div className={styles.start_parts__btn}>ОПЛАТИТЬ ДОЛЯМИ — 1&nbsp;250&nbsp;₽&nbsp;х&nbsp;4&nbsp;платежа</div>
                    </div> */}
                    <div className={styles.start_info}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/marafon_str2/tnkf_icon.svg" alt="" />
                        <p><span>ОТ 1&nbsp;665&nbsp;₽ В МЕСЯЦ</span></p>
                        <p>Этот марафон можно забрать в рассрочку. На 6&nbsp;месяцев без процентов и&nbsp;предварительного взноса, <strong>от 1&nbsp;665&nbsp;рублей в&nbsp;месяц</strong> (только для граждан РФ).<br/> Первый платёж через 30&nbsp;дней.</p>
                    </div>
                    <div className={styles.start_guarantee}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/marafon_anticellulite/guarantee.png" alt="" />
                        <div className={styles.start_guarantee__title}>Гарантия 100%</div>
                        <div className={styles.start_guarantee__text}>Мы уверены в&nbsp;своём продукте. Поэтому если в&nbsp;течение первых 3-х дней вы решите, что марафон вам не&nbsp;подошёл по&nbsp;каким-либо критериям, мы вернём деньги.</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Start;